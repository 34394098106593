<template>
  <div class="patient-list-container">
    <el-form id="searchForm" :inline="true" class="my-row" :model="searchForm" size="mini">
      <div>
        <el-form-item label="受试者编号">
          <el-input v-model="searchForm.external_patient_code" placeholder="请输入" clearable style="width: 120px" />
        </el-form-item>
        <el-form-item label="入组日期">
          <el-date-picker
            v-model="searchForm.date_arr"
            type="daterange"
            range-separator="至"
            size="mini" 
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 240px">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="P1">
          <el-select v-model="searchForm.p1_value" placeholder="请选择" size="mini" style="width: 84px" clearable>
            <el-option
              v-for="ii in p1Options"
              :key="ii.key"
              :label="ii.label"
              :value="ii.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="P2">
          <el-select v-model="searchForm.p2_value" placeholder="请选择" size="mini" style="width: 84px" clearable>
            <el-option
              v-for="ii in p1p2Options"
              :key="ii.key"
              :label="ii.label"
              :value="ii.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="临床分期">
          <el-select v-model="searchForm.stage" placeholder="请选择" size="mini" style="width: 84px" clearable>
            <el-option
              v-for="ii in stageOptions"
              :key="ii.key"
              :label="ii.label"
              :value="ii.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="EGFR">
          <el-select v-model="searchForm.mutation_state" placeholder="请选择" size="mini" style="width: 84px" clearable>
            <el-option
              v-for="ii in mutationOptions"
              :key="ii.key"
              :label="ii.label"
              :value="ii.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属分组">
          <el-select v-model="searchForm.group_type" placeholder="请选择" size="mini" style="width: 120px" clearable>
            <el-option
              v-for="ii in groupTypeOptions"
              :key="ii.key"
              :label="ii.label"
              :value="ii.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-on:click="onSearch">
            查找
          </el-button>
        </el-form-item>
        <el-form-item style="margin-right: 0">
          <el-button v-on:click="onReset">
            重置
          </el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table
      id="patientTable"
      ref="patientTable"
      :data="tableData"
      stripe
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      :height="dtHeight"
      style="width: 100%"
      class="patient-table">
      <el-table-column
        v-for="column in tableColumns"
        :label="column.name"
        :prop="column.field"
        :key="column.field"
        :min-width="column.minWidth"
        :width="column.width"
      >
      </el-table-column>
      <el-table-column label="操作" width="75px" fixed="right">
        <template slot-scope="scope">
          <el-dropdown placement="bottom" trigger="click">
            <span class="el-dropdown-link">
              <i class="el-icon-more" style="cursor: pointer;color: #0079fe"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="genetrials-common-dropdown">
              <el-dropdown-item @click.native="deleteItem(scope.row)">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination v-on:size-change="onPageSizeChange" v-on:current-change="onPageIdxChange"
      :current-page="pagination.page" :page-sizes="[20, 50, 100]" :page-size="pagination.size" align="right"
      layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
    </el-pagination>
    <el-dialog
      :visible.sync="dialogVisible"
      title="提示"
      width="30%"
      class="delete-patient-dialog"
      @confirm="handleDelete"
    >
      <span>删除受试者后不可恢复，请谨慎操作。</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleDelete">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  p1Options,
  p1p2Options,
  stageOptions,
  mutationOptions,
  groupTypeOptions
 } from './config'
import { apiPatientIndex, apiDeletePatient } from '@/apis/ECTOP/api'
import defaultResponseNotify from "@/helpers/message.js";
import dayjs from 'dayjs'
export default {
  name: "HomeIndex",
  data() {
    return {
      p1Options,
      p1p2Options,
      stageOptions,
      mutationOptions,
      groupTypeOptions,
      searchForm: {
        external_patient_code: '',
        date_arr: '',
        p1_value: '',
        p2_value: '',
        stage: '',
        mutation_state: '',
        group_type: ''
      },
      tableData: [],
      tableColumns: [{
        field: 'external_patient_code',
        name: '受试者编号'
      },{
        field: 'joinDate',
        name: '入组日期'
      },{
        field: 'p1Name',
        name: 'P1'
      },{
        field: 'p2Name',
        name: 'P2'
      },{
        field: 'stageName',
        name: '临床分期'
      },{
        field: 'mutationName',
        name: 'EGFR突变状态'
      },{
        field: 'groupTypeName',
        name: '所属分组',
        width: '350px'
      }],
      pagination: {
        page: 1,
        size: 20,
        total: 0
      },
      dialogVisible: false,
      operateRow: {},
      dtHeight: '500'
    };
  },
  created() {
    this.getList()
  },
  mounted() {
    this.$nextTick(() => {
      this.resizeDt();
      window.onresize = () => {
        this.resizeDt();
      };
    });
  },
  methods: {
    onPageSizeChange(val) {
      this.pagination.size = val
      this.getList()
    },
    onPageIdxChange(val) {
      this.pagination.page = val
      this.getList()
    },
    getList() {
      const params = {
        ...this.pagination,
        ...this.searchForm
      }
      params.begin_join_datetime = this.searchForm.date_arr ? dayjs(this.searchForm.date_arr[0]).format('YYYY-MM-DD') : ''
      params.end_join_datetime = this.searchForm.date_arr ? dayjs(this.searchForm.date_arr[1]).format('YYYY-MM-DD') : ''
      apiPatientIndex(params)
      .then(res => {
        if (res.code === 200) {
          res.entities.map(item => {
            item.joinDate = item.join_datetime ? dayjs(item.join_datetime).format('YYYY-MM-DD') : ''
            item.p1Name = this.getOptionName(item.p1_value, p1Options)
            item.p2Name = this.getOptionName(item.p2_value, p1p2Options)
            item.stageName = this.getOptionName(item.stage, stageOptions)
            item.mutationName = this.getOptionName(item.mutation_state, mutationOptions)
            item.groupTypeName = this.getOptionName(item.group_type, groupTypeOptions)
          })
          this.tableData = res.entities
          this.pagination.total = res.total
        }
      })
    },
    getOptionName(value, options) {
      if (value) {
        const findItem = options.find(item => {
          return item.key === value
        })
        if (findItem) {
          return findItem.label
        }
        return '--'
      }
      return '--'
    },
    deleteItem(row) {
      this.operateRow = row
      this.dialogVisible = true
    },
    handleDelete() {
      apiDeletePatient({
        patient_id: this.operateRow.patient_id
      }).then(res => {
        defaultResponseNotify(res);
        if (res.code === 200) {
          this.dialogVisible = false
          this.getList()
        }
      })
    },
    onSearch() {
      this.pagination.page = 1
      this.getList()
    },
    onReset() {
      this.searchForm = {
        external_patient_code: '',
        date_arr: '',
        p1_value: '',
        p2_value: '',
        stage: '',
        mutation_state: '',
        group_type: ''
      }
      this.onSearch()
    },
    resizeDt() {
      const zoom = document.getElementsByTagName('body')[0].style.zoom;
      const searchForm = document.getElementById('searchForm')
      const searchFormHeight = searchForm.clientHeight
      this.dtHeight = document.documentElement.clientHeight / zoom - (searchFormHeight + 110);
      const tb = document.querySelector(
        "#patientTable .el-table__body-wrapper"
      );
      tb.style.height = `${this.dtHeight}px`;
      tb.style.overflowY = `auto`;
    },
  }
};
</script>

<style>
.delete-patient-dialog {
  margin-top: 15vh!important;
}
</style>